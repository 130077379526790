<template>
    <div class="panel flex">
        <notify></notify>
        <navbar></navbar>
        <div :class="['main', this.$store.state.navBarOpened ? 'short' : 'long']">
            <topline></topline>
            <div class="main-contentarea">
                <router-view name="panelMain"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import navbar from "./components/navbar";
import topline from "./components/topline";
import notify from "./components/notify";

export default {
    name: "index",
    components: {
        navbar,
        topline,
        notify
    }
}
</script>

<style lang="scss">
@import "src/assets/scss/variables";
.panel {
    .main {
        width: 100%;
        transition: 0.2s;
        &.short {
            width: calc(100% - 260px);
            @media (max-width: $max-width) {
                width: 100%;
            }
        }
        &.long {
            width: 100%;
        }
        @media (max-width: 1200px) {
            width: 100%;
        }
        &-contentarea {
            padding: 20px;
            @media (max-width: 1200px) {
                padding: 10px;
            }
            .table {
                width: 100%;
                th {
                    text-align: left;
                    background: $cl-light;
                    padding: 10px 10px;
                    font-size: 14px;
                    vertical-align: top;
                }
                tr {
                    transition: .2s;
                    &:hover {background: $cl-light;}
                    td {
                        padding: 10px 10px;
                        font-size: 14px;
                        vertical-align: top;
                    }
                }
                .controls {
                    display: flex;
                    gap:10px;
                    flex-wrap: nowrap;
                    @media (max-width: $max-width) {
                        flex-direction: column;
                    }
                    .item {
                        display: block;
                        cursor: pointer;
                        &.edit {
                            color: $cl-yellow;
                        }
                        &.delete {
                            color: $cl-red;
                        }
                        &.list {
                            color: $cl-blue;
                        }
                    }
                }
            }
        }
        &-h1 {
            font-weight: 600;
            font-size: 28px;
            margin: 0 0 10px;
        }
    }
}
</style>