<template>
    <div @click="deletePush" class="push" :class="visible ? '' : 'visible'">Отменить подписку</div>
    <div @click="saveToken" class="push" :class="visible ? 'visible' : ''"><i class="material-icons">notifications</i></div>
</template>

<script>
import { getMessaging, getToken, deleteToken } from "firebase/messaging";
const messaging = getMessaging();
export default {
    name: 'push',
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        saveToken() {
            getToken(messaging, { vapidKey: 'BMBBqFAKFodEjYJU7RKvf6DcuqiRQQFfE8wbHXamIpch4qiX9-NFca0JdD2u9ebxnk9k-JLr8Iy-RvvgWoL2Bn8' }).then((currentToken) => {
                if (currentToken) {
                    this.$api.post(`push/token`, {token: currentToken}).then(res => {
                        if (res.data.success) {
                            this.$store.commit('showNotify', {
                                title: 'Сохранено',
                                content: 'Устройство подписано на уведомления',
                                type: 'success'
                            });
                            this.visible = false;
                            localStorage.setItem('pushToken', currentToken);
                        } else {
                            //this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                        }
                    })
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
        },
        deletePush() {
            deleteToken(messaging).then(result => {
                if(result) {
                    this.$api.post(`push/token/delete`, {token: localStorage.getItem('pushToken')}).then(res => {
                        if (res.data.success) {
                            this.$store.commit('showNotify', {
                                title: 'Сохранено',
                                content: 'Устройство отписано от уведомлений',
                                type: 'success'
                            });
                            this.visible = false;
                            localStorage.removeItem('pushToken');
                            this.visible = true;
                        } else {
                            //this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                        }
                    })
                }
            })
        }
    },
    created() {
        if (!localStorage.getItem('pushToken')) {
            this.visible = true;
        }
    }
}
</script>


<style scoped lang="scss">
.push {
    display: none;
    cursor: pointer;

    &.visible {
        display: block;
    }
}
</style>